import React, { Fragment, useState, useEffect } from 'react'
import { Button, Tag, Divider } from 'antd'
import { CloseCircleFilled, PlusOutlined, MinusOutlined } from "@ant-design/icons"
import { Nav, NavItem, Row, Col } from 'reactstrap'
import _ from 'lodash'
import i18next from 'i18next'

import { getSelectedLanguageContent, thousandSeperatorFormatter } from 'utils'
import { mixAndMatchCurrentStatus } from 'utils/purchase-upgrade-check'

const CartContent = props => {
  let {
    country,
    profile,
    windowHash,
    selectedPackages,
    selectedProducts,
    selectedLanguage,
    clientInfo,
    addPackageToCart,
    addProductToCart,
    removeProductFromCart,
    removePackageFromCart
  } = props
  const [subtotal, updateSubTotal] = useState(0)
  const [pointsSubtotal, updatePointsSubtotal] = useState(0)
  const [currentMixAndMatchStatus, updateCurrentMixAndMatchStatus] = useState({})

  useEffect(() => {
    clientInfo.is_mix_and_match_available && updateCurrentMixAndMatchStatus(mixAndMatchCurrentStatus(windowHash.includes('/upgrade') ? _.filter(clientInfo.client_overall_config.mixAndMatch, val => val.tierChange !== "UFIT_DISTRIBUTOR") : clientInfo.client_overall_config.mixAndMatch, pointsSubtotal, profile))
  }, [pointsSubtotal])

  useEffect(() => {
    updateSubTotal(_.sum([_.sumBy(selectedProducts, item => item.quantity * item.price), _.sumBy(selectedPackages, item => item.quantity * item.price)]))
    updatePointsSubtotal(_.sum([_.sumBy(selectedProducts, item => item.quantity * item.points), _.sumBy(selectedPackages, item => item.quantity * item.points)]))
  }, [selectedPackages, selectedProducts])

  return (
    <>
      {
        _.isEmpty(selectedProducts) && _.isEmpty(selectedPackages) ? (
          <div className="d-flex align-items-center justify-content-center" style={{ height: "100px " }}>
            {i18next.t('EMPTY_CART_MESSAGE')}
          </div>
        ) : (
          <>
            <div className="cart-list">
              <Nav vertical style={{ flexWrap: "unset" }}>
                {selectedProducts.length > 0 && <Divider orientation="left">{i18next.t('PRODUCTS')}</Divider>}
                {selectedProducts.map(item => (
                  <Fragment key={item.id}>
                    <NavItem className="nav-item-divider my-1" />
                    <NavItem className="nav-update-lang px-2">
                      <Row className="align-items-center" style={{ width: "100%" }}>
                        <Col xs={2}>
                          <div className='square-image-container'>
                            <img src={(Array.isArray(item.site_product_images) && item.site_product_images.length > 0) ?
                              item.site_product_images[0]?.url :
                              clientInfo.logo_image_url} />
                          </div>
                        </Col>
                        <Col xs={4} className="font-weight-bold px-2">
                          <div>{getSelectedLanguageContent(item.name, selectedLanguage)}</div>
                          <div className="d-flex flex-wrap">
                            {
                              !_.isEmpty(item.variant_level1) && (
                                <Tag color="magenta" className='mb-1'>{item.variant_level1}</Tag>
                              )
                            }
                            {
                              !_.isEmpty(item.variant_level2) && (
                                <Tag color="green" className='mb-1'>{item.variant_level2}</Tag>
                              )
                            }
                          </div>
                        </Col>
                        <Col xs={3}>
                          <div className="d-flex justify-content-around mb-1">{i18next.t('QUANTITY')}</div>
                          <div className="d-flex justify-content-around">
                            {
                              !((windowHash.indexOf('upgrade') > -1 || windowHash.indexOf('register') > -1) && !clientInfo.is_mix_and_match_available) && (
                                <Button
                                  shape='circle'
                                  className="small-cart-icon-button"
                                  onClick={() => addProductToCart(item, -1)}
                                  icon={<MinusOutlined />} />
                              )
                            }
                            <span>{`x${item.quantity}`}</span>
                            {
                              !((windowHash.indexOf('upgrade') > -1 || windowHash.indexOf('register') > -1) && !clientInfo.is_mix_and_match_available) && (
                                <Button
                                  shape='circle'
                                  className="small-cart-icon-button"
                                  onClick={() => addProductToCart(item, 1)}
                                  icon={<PlusOutlined />} />
                              )
                            }
                          </div>
                        </Col>
                        <Col xs={2}>
                          <div>{`${country.currency_symbol} ${thousandSeperatorFormatter(item.quantity * item.price)}`}</div>
                          <div className="point-display text-muted">{`${thousandSeperatorFormatter(item.quantity * item.points, 0)} ${clientInfo.point_name}`}</div>
                        </Col>
                        <Col xs={1} className="text-center">
                          <Button
                            onClick={() => removeProductFromCart(item.id)}
                            shape="circle"
                            icon={<CloseCircleFilled style={{ fontSize: "16px" }} />}
                            type="text" />
                        </Col>
                      </Row>
                    </NavItem>
                  </Fragment>
                ))}
                {Array.isArray(selectedPackages) && selectedPackages.length > 0 && <Divider orientation="left">{i18next.t('PACKAGES')}</Divider>}
                {Array.isArray(selectedPackages) && selectedPackages.map(item => (
                  <Fragment key={item.id}>
                    <NavItem className="nav-item-divider my-1" />
                    <NavItem className="nav-update-lang px-2">
                      <Row className="align-items-center" style={{ width: "100%" }}>
                        <Col xs={2}>
                          <div className='square-image-container'>
                            <img src={(Array.isArray(item.site_package_images) && item.site_package_images.length > 0) ?
                              item.site_package_images[0]?.url :
                              clientInfo.logo_image_url} />
                          </div>
                        </Col>
                        <Col xs={4} className="font-weight-bold px-2">
                          <div>{getSelectedLanguageContent(item.name, selectedLanguage)}</div>
                          <div className="d-flex">
                            <Tag color="lime">{i18next.t('PACKAGES')}</Tag>
                          </div>
                        </Col>
                        <Col xs={3} className="text-center">
                          <div className="d-flex justify-content-around mb-1">{i18next.t('QUANTITY')}</div>
                          <div className="d-flex justify-content-around">
                            {
                              !((windowHash.indexOf('upgrade') > -1 || windowHash.indexOf('register') > -1) && !clientInfo.is_mix_and_match_available) && (
                                <Button
                                  shape='circle'
                                  className="small-cart-icon-button"
                                  onClick={() => addPackageToCart(item, -1)}
                                  icon={<MinusOutlined />} />
                              )
                            }
                            <span>{`x${item.quantity}`}</span>
                            {
                              !((windowHash.indexOf('upgrade') > -1 || windowHash.indexOf('register') > -1) && !clientInfo.is_mix_and_match_available) && (
                                <Button
                                  shape='circle'
                                  className="small-cart-icon-button"
                                  onClick={() => addPackageToCart(item, 1)}
                                  icon={<PlusOutlined />} />
                              )
                            }
                          </div>
                        </Col>
                        <Col xs={2}>
                          <div>{`${country.currency_symbol} ${thousandSeperatorFormatter(item.quantity * item.price)}`}</div>
                          <div className="point-display text-muted">{`${thousandSeperatorFormatter(item.quantity * item.points, 0)} ${clientInfo.point_name}`}</div>
                        </Col>
                        <Col xs={1} className="text-center">
                          <Button
                            onClick={() => removePackageFromCart(item)}
                            shape="circle"
                            icon={<CloseCircleFilled style={{ fontSize: "16px" }} />}
                            type="text" />
                        </Col>
                      </Row>
                    </NavItem>
                  </Fragment>
                ))}
              </Nav>
            </div>
            <div className="d-flex justify-content-between align-items-center px-2 my-2 subtotal-row">
              <div className="p-3 text-right text-muted">
                {i18next.t('SUB_TOTAL')}:
              </div>
              <div className="p-3">
                <div className='font-weight-bold'>{`${country.currency_symbol} ${thousandSeperatorFormatter(subtotal)}`}</div>
                <div className="point-display text-muted">{`${thousandSeperatorFormatter(pointsSubtotal)} ${clientInfo.point_name}`}</div>
              </div>
            </div>
            {
              (windowHash.indexOf('upgrade') > -1 || windowHash.indexOf('register') > -1) && clientInfo.is_mix_and_match_available && (
                <Row className="px-2">
                  <Col className='px-2' xs={6}>
                    <div>
                      <div className="font-weight-bold">
                        {i18next.t('CURRENT_UPGRADE')}:
                      </div>
                      <div>
                        {i18next.t('ROLE')}: {currentMixAndMatchStatus.current?.roleChange || "None"}
                      </div>
                      <div className="mb-2">
                        {i18next.t('TIER')}: {_.find(clientInfo.tiers, { id: currentMixAndMatchStatus.current?.tierChange })?.name || "None"}
                      </div>
                    </div>
                  </Col>
                  {
                    !_.isEmpty(currentMixAndMatchStatus.next) && (
                      <Col className='px-2' xs={6}>
                        <div className="font-weight-bold">
                          {i18next.t('NEXT_UPGRADE')}:
                        </div>
                        <div>
                          {i18next.t('ROLE')}: {currentMixAndMatchStatus.next?.roleChange || "None"}
                        </div>
                        <div>
                          {i18next.t('TIER')}: {_.find(clientInfo.tiers, { id: currentMixAndMatchStatus.next?.tierChange })?.name || "None"}
                        </div>
                      </Col>
                    )
                  }
                  <div className="font-weight-bold mb-2 px-2" style={{ color: "#ff0000" }}>
                    {
                      !_.isEmpty(currentMixAndMatchStatus.next) ?
                        `Earn ${currentMixAndMatchStatus.next.amount - pointsSubtotal} ${clientInfo.point_name} more to reach next upgrade` :
                        i18next.t('ALL_UPGRADE_REACHED')
                    }
                  </div>
                </Row>
              )
            }
          </>
        )
      }
    </>
  )
}

export default CartContent