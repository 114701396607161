import React from 'react'

import { applyColor } from 'utils/apply-color'
import { checkIfComponentHidden } from 'utils'

import Div from './Div'
import TechBaseCarousel from './Carousel'
import TechBaseButton from './Button'
import TechBaseCharts from './Charts'
import TechBaseChartFilter from './Charts/ChartFilter'
import TechBaseChartContent from './Charts/ChartContent'
import Announcement from './Announcement'
import TechBaseImage from './Image'
import ReferralLink from './ReferralLink'
import TechBaseIcon from './Icon'
import TechBaseForm from './Form'
import TechBaseInput from './Form/Input'
import TechBaseMultilingualInput from './Form/MultilingualInput'
import TechBaseCaptcha from './Form/Captcha'
import TechBaseFooter from './Footer'
import TechBaseCard from './Card'
import TechBaseSelect from './Form/Select'
import TechBasePageTitle from './PageTitle'
import TechBaseTable from './Tablev7'
import TechBaseModal from './Modal'
import TechBaseTab from './Tab'
import TechBaseText from './Text'
import TechBaseFilePond from './FilePond'
import TechBaseCardList from './CardList'
import ProductVariant from './Product/ProductVariant'
import ImageSlider from './Image/ImageSlider'
import TechBaseCollapse from './Collapse'
import PackageProductList from './PackageProductList'
import TechBaseSteps from './Steps'
import TechBaseDivider from './Divider'
import CustomList from './CustomList'
import Tree from './Tree'
import PDFViewer from './PDFViewer'
import WalletList from './WalletList'
import DropDownButton from './DropDownButton'
import LanguageSwitcher from 'components/LanguageSwitcher'
import TechBaseRadio from './Form/Radio'
import TechBaseCheckbox from './Form/Checkbox'
import PaymentGateway from './PaymentGateway'
import TechBaseDescriptions from './Descriptions'
import TechBaseProgress from './Progress'
import TechBaseStatistic from './Statistic'
import TechBaseTag from './Tag'
import TechBaseAvatar from './Avatar'
import BoardList from './BoardList'
import ZitronRow from './Div/row'
import ZitronColumn from './Div/column'
import TechBaseSwiper from './Image/Swiper'
import TechBaseQuillEditor from './QuillEditor'
import DefaultComponent from './default'
import _ from 'lodash'

const DisplayCore = props => {
  let { item } = props
  if (_.isEmpty(item) || checkIfComponentHidden(props.hideComponent, item, props)) {
    return <></>
  }
  switch (item.type) {
    case 'div': return <Div {...props} />
    case 'row': return <ZitronRow {...props} />
    case 'col': return <ZitronColumn {...props} />
    case 'carousel': return <TechBaseCarousel {...props} />
    case 'button': return <TechBaseButton {...props} />
    case 'text': return <TechBaseText {...props} />
    case 'icon': return <TechBaseIcon {...props} />
    case 'img': return <TechBaseImage {...props} />
    case 'chart': return <TechBaseCharts {...props} />
    case 'chart-filter': return <TechBaseChartFilter {...props} />
    case 'chart-content': return <TechBaseChartContent {...props} />
    case 'announcement': return <Announcement {...props} />
    case 'referral-link': return <ReferralLink {...props} />
    case 'form': return <TechBaseForm {...props} />
    case 'input': return <TechBaseInput {...props} />
    case 'multilingual-input': return <TechBaseMultilingualInput {...props} />
    case 'select': return <TechBaseSelect {...props} />
    case 'captcha': return <TechBaseCaptcha {...props} ref={props.captchaRef} />
    case 'footer': return <TechBaseFooter {...props} />
    case 'card': return <TechBaseCard {...props} />
    case 'tab': return <TechBaseTab {...props} />
    case 'br': return <br />
    case 'page-title': return <TechBasePageTitle {...props} />
    case 'table': return <TechBaseTable {...props} ref={props.tableRef} />
    case 'modal': return <TechBaseModal {...props} />
    case 'file-pond': return <TechBaseFilePond {...props} />
    case 'card-list': return <TechBaseCardList {...props} />
    case 'product-variant': return <ProductVariant {...props} />
    case 'image-slider': return <ImageSlider {...props} />
    case 'collapse': return <TechBaseCollapse {...props} />
    case 'package-product-list': return <PackageProductList {...props} />
    case 'steps': return <TechBaseSteps {...props} />
    case 'divider': return <TechBaseDivider  {...props} />
    case 'custom-list': return <CustomList  {...props} />
    case 'tree': return <Tree  {...props} />
    case "pdf-viewer": return <PDFViewer {...props} />
    case 'style': return <style>{applyColor(item.content, props.colors)}</style>
    case 'wallet-list': return <WalletList {...props} />
    case 'drop-down': return <DropDownButton {...props} />
    case 'language-switcher': return <LanguageSwitcher {...props} />
    case 'radio': return <TechBaseRadio {...props} />
    case 'checkbox': return <TechBaseCheckbox {...props} />
    case 'payment-gateway': return <PaymentGateway {...props} />
    case 'description': return <TechBaseDescriptions {...props} />
    case 'progress': return <TechBaseProgress {...props} />
    case 'statistic': return <TechBaseStatistic {...props} />
    case 'tag': return <TechBaseTag {...props} />
    case 'avatar': return <TechBaseAvatar {...props} />
    case 'board-list': return <BoardList  {...props} />
    case 'swiper': return <TechBaseSwiper {...props} />
    case 'rte-quill-editor': return <TechBaseQuillEditor {...props} />;
    default: return <DefaultComponent {...props} />
  }
}

export default DisplayCore