import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { Navigation, Thumbs } from "swiper"; // ✅ FIXED: Use from "swiper" instead of "swiper/modules"
import _ from "lodash";
import { handleNestedObjectKey } from "utils";
import "./index.scss";

const FALLBACK_IMAGE = "https://mlm-solution-assets-staging.s3.ap-southeast-1.amazonaws.com/staging/assets/client-logos/nh044dvf/Ufit";

const ImageSlider = ({ data, dataKey, urlKey }) => {
  const [activeThumb, setActiveThumb] = useState(null);
  const [slides, setSlides] = useState([]);
  const [packageImages, setPackageImages] = useState([]);

  useEffect(() => {
    const newImages = _.get(data, dataKey, []).filter(Boolean);
    if (!_.isEqual(newImages, packageImages)) {
      setPackageImages(newImages);
    }
  }, [data, dataKey, packageImages]);

  useEffect(() => {
    setSlides(packageImages.length ? packageImages : [{ url: FALLBACK_IMAGE }]);
  }, [packageImages]);

  const handleImageError = (event) => {
    event.target.onerror = null;
    event.target.src = FALLBACK_IMAGE;
  };

  return (
    <>
      <Swiper
        loop={true}
        spaceBetween={10}
        navigation
        modules={[Navigation, Thumbs]}
        grabCursor={true}
        thumbs={{ swiper: activeThumb }}
        className="product-images-slider mb-2"
      >
        {slides.map((img, index) => (
          <SwiperSlide key={index}>
            <img
              src={_.get(img, urlKey, img.url) || FALLBACK_IMAGE}
              alt="Product Image"
              onError={handleImageError}
              style={{ objectFit: "contain", width: "100%", height: "100%" }}
            />
          </SwiperSlide>
        ))}
      </Swiper>

      {slides.length > 1 && (
        <Swiper
          onSwiper={setActiveThumb}
          loop={slides.length > 4}
          spaceBetween={10}
          slidesPerView={4}
          modules={[Navigation, Thumbs]}
          className="product-images-slider-thumbs"
        >
          {slides.map((img, index) => (
            <SwiperSlide key={index}>
              <div className="product-images-slider-thumbs-wrapper">
                <img
                  src={_.get(img, urlKey, img.url) || FALLBACK_IMAGE}
                  alt="Thumbnail"
                  onError={handleImageError}
                  style={{ objectFit: "contain", width: "100%", height: "100%" }}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </>
  );
};

export default ImageSlider;
