import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Form } from 'antd';

const TechBaseQuillEditor = ({ item, form }) => {
  const { name, label, extra, rules, style, className, inputConfig } = item || {};
  const [editorValue, setEditorValue] = useState('');

  useEffect(() => {
    if (!form) return; // ✅ Ensure hooks execute even if form is undefined

    const formValue = form.getFieldValue(name) || '';
    if (formValue !== editorValue) {
      setEditorValue(formValue);
    }
  }, [form, name]);

  const handleChange = (value) => {
    setEditorValue(value);
    if (form) {
      form.setFieldsValue({ [name]: value });
    }
  };

  if (!form) {
    console.error("TechBaseQuillEditor - Form instance is undefined!");
    return null; // ✅ Only return after hooks have run
  }

  return (
    <Form.Item name={name} className={className} label={label} extra={extra} style={style} rules={rules}>
      <ReactQuill
        theme="snow"
        modules={inputConfig?.modules}
        formats={inputConfig?.formats}
        value={editorValue}
        onChange={handleChange}
      />
    </Form.Item>
  );
};

export default TechBaseQuillEditor;
