import _ from 'lodash'
import i18next from 'i18next'

export const handleErrorMsg = error => {
  if (!_.isEmpty(error.info)) {
    return (
      <>
        {
          _.map(error.info, (val, key) => typeof val === 'object' && val.field ? <div>{`${val.field}: ${val.message}`}</div> : <div>{`${key}: ${val}`}</div>)
        }
      </>
    )
  } else if (error.error) {
    return (
      <>
        {
          _.map(error.error, val => <div>{val.message}</div>)
        }
      </>
    )
  } else {
    return error.message || error
  }
}

export const getSelectedLanguageContent = (item, selectedLanguage = 'en') => {
  if (typeof item === "number") {
    return item
  } else if (typeof item === "string") {
    return translateAndReplace(item)
  } else {
    if (!_.isEmpty(item)) {
      if (selectedLanguage && item[selectedLanguage]) {
        return item[selectedLanguage]
      } else {
        return item['en']
      }
    } else {
      return ""
    }
  }
}

const translateAndReplace = (inputString) => {
  const regex = /\{\{([^}]+)\}\}/g;
  let replacedString = inputString
  if (i18next.language === 'zh') {
    replacedString = replacedString.replaceAll("}} {{", "}}{{")
  }
  replacedString = replacedString.replace(regex, (match, group) => {
    let key = group.trim();
    key = key.replaceAll('-', '_')
    return i18next.t(key.toUpperCase()) || match;
  });
  return replacedString;
};

export const hexToRgb = hex => {
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b
  })

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null
}

export const getContraColor = (hex, threshold = 150, light = '#ffffff', dark = '#000000') => {
  if (hex) {
    let color = hexToRgb(hex)
    if (color && (color.r * 0.299 + color.g * 0.587 + color.b * 0.114) > threshold) {
      return dark
    } else {
      return light
    }
  }
}

export const handleNestedObjectKey = (data, key) => {
  let tmp = _.cloneDeep(key)
  let tmpData = _.cloneDeep(data)
  if (typeof key === "string") {
    tmp = tmp.split('.')
  }
  if (!_.isEmpty(tmp)) {
    tmp?.map(tmpKey => tmpData = tmpData ? tmpData[tmpKey] : tmpData)
    return tmpData
  }
  return undefined
}

export const checkIfComponentHidden = (hideComponent, item = {}, ...args) => {
  if (item.isHide && typeof hideComponent === 'function') {
    if (item.isHide.includes('||')) {
      return item.isHide.split('||').some(key => {
        if (key[0] === '!') {
          return !hideComponent(key.slice(1), ...args)
        } else {
          return hideComponent(key, ...args)
        }
      })
    } else return item.isHide.split('&&').every(key => {
      if (key[0] === '!') {
        return !hideComponent(key.slice(1), ...args)
      } else {
        return hideComponent(key, ...args)
      }
    })
  }
  return false
}

export const checkIfComponentDisabled = (disabledComponent, item, ...args) => {
  if (item.isDisabled && typeof item.isDisabled === 'boolean') {
    return item.isDisabled
  }
  else if (item.isDisabled && typeof disabledComponent === 'function') {
    let tmp = false
    if (item.isDisabled[0] === '!') {
      tmp = !disabledComponent(item.isDisabled.slice(1), ...args)
    } else {
      tmp = disabledComponent(item.isDisabled, ...args)
    }
    return tmp === true
  }
  return false
}

export const isOver18YearsOld = date_of_birth => {
  let today = new Date()
  let birthday = new Date(date_of_birth)
  let over18yo = false
  let tmpYear = parseInt(today.getFullYear()) - parseInt(birthday.getFullYear())
  if (tmpYear > 18) {
    over18yo = true
  } else if (tmpYear === 18) {
    let tmpMonth = parseInt(today.getMonth()) - parseInt(birthday.getMonth())
    if (tmpMonth > 0) {
      over18yo = true
    } else if (tmpMonth === 0) {
      if (parseInt(today.getDate()) - parseInt(birthday.getDate()) >= 0) {
        over18yo = true
      }
    }
  }
  return over18yo
}

export const capitalizeFirstLetter = (text) => {
  let firstLetter = text.slice(0, 1)
  let otherLetters = text.slice(1)
  firstLetter = firstLetter.toUpperCase()
  return firstLetter + otherLetters
}

export const sortByOrder = list => {
  return list.sort((a, b) => {
    let sort_a = a?.sort || 999
    let sort_b = b?.sort || 998
    return sort_a - sort_b
  })
}

export const truncateTextWithEllipsis = (inputHtml, maxChar) => {
  if (maxChar == null || maxChar <= 0) {
    return inputHtml;
  }

  const plainText = inputHtml.replace(/<[^>]+>/g, '')
  if (plainText.length <= maxChar) {
    return inputHtml
  }

  let charCount = 0;
  const regex = /<[^>]+>|[^<]+/g;
  const truncatedHtml = inputHtml.replace(regex, match => {
    if (match.startsWith('<')) {
      return match;
    }

    const remainingChars = maxChar - charCount;
    if (match.length <= remainingChars) {
      charCount += match.length;
      return match;
    }

    const truncatedText = match.slice(0, remainingChars)
    charCount += truncatedText.length
    return truncatedText + '...';
  });

  return truncatedHtml;
}

export const checkIfDeactivedStatus = (status) => {
  return status === 'suspended' || status === 'terminated' || status === 'inactive' || status === 'ufit-termination'
}

export const intToAlphabet = (num) => {
  let result = '';
  while (num > 0) {
    num--; // Decrement num by 1 to make the range 0-25 for alphabets A-Z
    const remainder = num % 26;
    const char = String.fromCharCode(65 + remainder); // Convert to corresponding alphabet
    result = char + result; // Prepend the character to the result string
    num = Math.floor(num / 26); // Divide num by 26
  }
  return result;
}

export const thousandSeperatorFormatter = (val, decimal_points = 2) => {
  if (!val) {
    return (0).toFixed(decimal_points);
  }

  let temp = `${val}`.replace(/[^0-9.-]/g, "");
  temp = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: decimal_points,
    maximumFractionDigits: decimal_points
  }).format(temp);

  return temp;
};
