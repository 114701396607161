import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { Row, Col } from 'reactstrap'
import { Badge, Button, Popover } from 'antd'
import { ShoppingCartOutlined } from "@ant-design/icons"
import { connect } from "react-redux"
import i18next from 'i18next'

import CartContent from './CartContent'
import './index.scss'
import { getContraColor } from 'utils'
import { applyColor } from 'utils/apply-color'
import { addPackageToCart, addProductToCart, removeProductFromCart, removePackageFromCart } from 'reducers/ShopCart'

const ShoppingCart = props => {
  let {
    colors,
    country,
    profile,
    clientInfo,
    cartPackages,
    cartProducts,
    windowHash,
    cartUpgradePackages,
    cartUpgradeProducts,
    cartRedemptionPackages,
    cartRedemptionProducts,
    selectedLanguage,
    addPackageToCart,
    addProductToCart,
    removeProductFromCart,
    removePackageFromCart
  } = props

  const [selectedPackages, updateSelectedPackages] = useState([])
  const [selectedProducts, updateSelectedProducts] = useState([])

  useEffect(() => {
    if (windowHash.indexOf('upgrade') > -1) {
      updateSelectedPackages(cartUpgradePackages || []);
      updateSelectedProducts(cartUpgradeProducts || []);
    } else if (windowHash.indexOf('redemption') > -1) {
      updateSelectedPackages(cartRedemptionPackages || []);
      updateSelectedProducts(cartRedemptionProducts || []);
    } else {
      updateSelectedPackages(cartPackages || []);
      updateSelectedProducts(cartProducts || []);
    }
  }, [windowHash, cartUpgradePackages, cartUpgradeProducts, cartRedemptionPackages, cartRedemptionProducts, cartPackages, cartProducts]);

  const popOverContent = (
    <>
      <style>{`.cart-content{ color: ${applyColor('$quatenary', colors)} }`}</style>
      <div className="dropdown-menu-header mb-0" style={{ minWidth: "200px" }}>
        <div className="dropdown-menu-header-inner" style={{ padding: '0.8rem 0.5rem' }}>
          <div className="menu-header-content text-dark">
            <h5 className="menu-header-title">
              {windowHash.indexOf('upgrade') > -1 ? i18next.t('UPGRADE_CART') : i18next.t('MALL_CART')}
            </h5>
          </div>
        </div>
      </div>
      <CartContent
        colors={colors}
        country={country}
        profile={profile}
        windowHash={windowHash}
        selectedPackages={selectedPackages}
        selectedProducts={selectedProducts}
        selectedLanguage={selectedLanguage}
        clientInfo={clientInfo}
        removeProductFromCart={removeProductFromCart}
        removePackageFromCart={removePackageFromCart}
        addPackageToCart={(item, quantity) => addPackageToCart({ payload: { ...item, quantity: quantity || 1 }, disableToast: true })}
        addProductToCart={(item, quantity) => addProductToCart({ payload: { ...item, quantity: quantity }, disableToast: true })} />
      {
        !(_.isEmpty(selectedProducts) && _.isEmpty(selectedPackages)) && (
          <Row>
            <Col className='px-2' xs={6}>
            </Col>
            <Col className='px-2' xs={6}>
              <Button
                href={`#/dashboard${window.location.hash.indexOf('upgrade') > -1 ? '/upgrade' : window.location.hash.indexOf('redemption') > -1 ? '/redemption' : ''}/checkout`}
                shape="round"
                className='cart-button custom-color'
                type="primary"
                style={{ color: getContraColor(applyColor('$primary', colors)), backgroundColor: applyColor('$primary', colors) }}>
                {i18next.t('PROCEED_TO_CHECKOUT')}
              </Button>
            </Col>
          </Row>
        )
      }
    </>
  )

  return (
    <Popover content={popOverContent} trigger='click'>
      <Button type="text">
        <Badge count={_.sumBy(selectedProducts, "quantity") + _.sumBy(selectedPackages, "quantity")} size="small">
          <ShoppingCartOutlined style={{ fontSize: "24px", color: clientInfo.name === 'Winna' ? applyColor('$primary', colors) : getContraColor(applyColor('$primary', colors)) }} />
        </Badge>
      </Button>
    </Popover>
  )
}

const mapStateToProps = (state) => ({
  data: state,
  colors: state.ClientReducer.clientInfo.styles?.colors,
  selectedLanguage: state.ProfileReducer.profile.preferred_language,
  clientInfo: state.ClientReducer.clientInfo,
  cartProducts: state.ShopCartReducer.cartProducts || [],
  cartPackages: state.ShopCartReducer.cartPackages || [],
  cartUpgradeProducts: state.ShopCartReducer.cartUpgradeProducts || [],
  cartUpgradePackages: state.ShopCartReducer.cartUpgradePackages || [],
  cartRedemptionProducts: state.ShopCartReducer.cartRedemptionProducts || [],
  cartRedemptionPackages: state.ShopCartReducer.cartRedemptionPackages || [],
  country: state.ProfileReducer.country,
  clientCountry: state.ProfileReducer.clientCountry,
  profile: state.ProfileReducer.profile
})
export default connect(mapStateToProps, { addPackageToCart, addProductToCart, removeProductFromCart, removePackageFromCart })(ShoppingCart)