import React, { Fragment } from "react";
import { connect } from "react-redux";
import { useResizeDetector } from "react-resize-detector";
import cx from "classnames";

const Main = (props) => {
  const { width, ref } = useResizeDetector();

  const {
    colorScheme,
    enableFixedHeader,
    enableFixedSidebar,
    enableFixedFooter,
    enableClosedSidebar,
    enableMobileMenu,
    enablePageTabsAlt,
  } = props;

  return (
    <Fragment>
      <div
        ref={ref}
        className={cx(
          "app-container app-theme-" + colorScheme,
          { "fixed-header": enableFixedHeader },
          { "fixed-sidebar": enableFixedSidebar || width < 1250 },
          { "fixed-footer": enableFixedFooter },
          { "closed-sidebar": enableClosedSidebar || width < 1250 },
          { "closed-sidebar-mobile": width < 1250 },
          { "sidebar-mobile-open": enableMobileMenu },
          { "body-tabs-shadow-btn": enablePageTabsAlt }
        )}
      >
        {props.children}
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  colorScheme: state.ThemeOptions.colorScheme,
  enableFixedHeader: state.ThemeOptions.enableFixedHeader,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableFixedFooter: state.ThemeOptions.enableFixedFooter,
  enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
  enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
});

export default connect(mapStateToProps)(Main);
